import { lazy, Suspense, useEffect } from 'react'
import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import { formatISO } from 'date-fns'
import {
  BetaFailDaily,
  BetaFailHistory,
} from './pages/settlement/failAnalysis/FailAnalysis'
import {
  CountryCodePage,
  FISIRSISOCountryReconciliationPage,
  P3MarketMakerSecuritiesPage,
  P3ReconciliationPage,
  SecuritiesToP3Translator,
  BetaStalePricingPage,
  P3StalePricingPage,
  BetaStalePricingPageHistorical,
  P3StalePricingPageHistorical,
  ReconHistoryPage,
} from './pages/staticData/StaticData'
import {
  P3FailAnalysisDaily,
  P3FailAnalysisHistory,
} from './pages/settlement/failAnalysis/FailAnalysis'
const HomePage = lazy(() => import('./pages/HomePage'))
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'))
import {
  IPublicClientApplication,
  InteractionType,
  InteractionRequiredAuthError,
  EventMessage,
  AuthenticationResult,
  EventType,
} from '@azure/msal-browser'
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { loginRequest } from './utils/authConfig'
import {
  TradeBreakByMPID,
  TradeDetails,
  TradeBreaksByEC,
  IntraDayBreaksByEC,
  IntraDayBreaksByMPID,
  IntraDayTradeDetails,
} from './pages/tradeRecon/tradeManagementPage'
import ErrorPage from './pages/error/error'
import BuyInReceiveSettlements from './pages/settlement/buyInSettlements/buyInReceiveSettlements'
import BuyInDeliverSettlements from './pages/settlement/buyInSettlements/buyInDeliverSettlements'
import SuspenseAccount from './pages/tradeRecon/suspenseAccount/suspenseAccount'
import TradeFix from './pages/tradeRecon/tradeRemediation/tradeFix'
import TradeRemediation from './pages/tradeRecon/tradeRemediation/traderemediation'
import AboutPage from './pages/about/aboutPage'
import StaticDataDashboard from './pages/staticData/dashboard/staticDataDashboard'
import FailReport204 from './pages/settlement/failReport204InSettlements/FailReport204'
import TradeReconDashboard from './pages/tradeRecon/dashboard/tradeReconDashboard'
import {
  getHoliDay,
  setFormateDate,
  setIntraDayMinDate,
} from './store/dash-board/about'
import HoliDay from './utils/holiday'
import { useDispatch } from 'react-redux'
import SettlementDashboard from './pages/settlement/dashboard/SettlementDashboard'
import JAMSJobReport from './pages/techOps/jamsJobReport/JAMSJobReport'
import {
  ADRFeesUploadPage,
  ADRPastFileUploadPage,
  DividendReconReport,
  ReeOrgFeeForACAPSPage,
  ReOrgAdminPage,
  VoluntaryEventsTrackingPage,
} from './pages/assetServicing/AssetServicing'
import SecurityUpdates from './pages/staticData/securityUpdate/securityUpdate'
import { SettlementConfiguration } from './pages/settlement/settlementConfig/ThresholdPage'
import { TradeConfiguration } from './pages/tradeRecon/tradeConfig/ThresholdPage'
import ContraCorrespondent from './pages/settlement/contraCorrespondent/contraCorrespondentDetails'
import JamsJobDashboard from './pages/techOps/jamsJobReport/dashboard/JamsJobDashboard'
import IntradayTradeDashboard from './pages/tradeRecon/dashboard/intradayTradeBreakDashboard'
import RegSHOPage from './pages/regSHOTool/RegSHOHomePage'
import BetaTradeReportPage from './pages/settlement/shortSales/BetaTradeReportPage'
import BetaSecLendingPage from './pages/settlement/betaSecLending/BetaSecLendingPage'
import BETALoanetRecon from './pages/operationsControl/BETALoanetReconciliation/BETALoanetRecon'
import RegShoHistoryPage from './pages/settlement/regShoHistorical/RegShoHistoryPage'
import CATCAISDashboard from './pages/cais/dashboard/CATCAISDashboard'
import SecurityUnspecifiedFieldDetails from './pages/staticData/securityUpdate/securityUnspecifiedFieldDetails'
import RegShoHistoryAudit from './pages/settlement/regShoHistoricalAuditTrail/RegShoHistoryAuditPage'
import OutStandingPage from './pages/settlement/outStandingReport/OutStandingPage'
import ErrorsReport from './pages/cais/dashboard/ErrorsReport'
import { useIdleTimer } from 'react-idle-timer'
import BuyInAdminWindow from './pages/settlement/buyInSettlements/buyInAdminWindow'
import StreetSettlementsSuspenseAccount from './pages/operationsControl/streetSettlementsSuspenseAccount/StreetSettlementsSuspenseAccount'

type AppProps = {
  msalInstance: IPublicClientApplication
}

const AllRoutes = () => {
  const { instance, accounts } = useMsal()
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
    }
  })

  useEffect(() => {
    if (!isAuthenticated) {
      instance
        .ssoSilent({
          scopes: ['user.read'],
          prompt: 'select_account',
        })
        .then((response) => {
          instance.setActiveAccount(response.account)
        })
        .catch((error) => {
          console.log('Auth error', error)
        })
    }
  }, [accounts])

  useEffect(() => {
    async function HolidayData() {
      const AllHoliDays = await dispatch(getHoliDay())
      localStorage.setItem(
        'DefaultDate',
        JSON.stringify(AllHoliDays.recentTradeDate)
      )
      localStorage.setItem(
        'DefaultDate',
        JSON.stringify(AllHoliDays.recentTradeDate)
      )
      const response: [] = await HoliDay(AllHoliDays.lstHolidays)
      await dispatch(setFormateDate(response))
      await dispatch(
        setIntraDayMinDate(
          formatISO(new Date(AllHoliDays.intradayMinDate) || new Date())?.split(
            'T'
          )[0]
        )
      )
    }
    HolidayData()
  }, [])

  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })

  return (
    <Routes>
      <Route element={<HomePage />}>
        <Route path="/tradeReconDashboard" element={<TradeReconDashboard />} />
        <Route path="/CaisDashboard" element={<CATCAISDashboard />} />
        <Route path="/errorsDetailedReport" element={<ErrorsReport />} />
        <Route
          path="/intradayTradeReconDashboard"
          element={<IntradayTradeDashboard />}
        />
        <Route path="/staticDashboard" element={<StaticDataDashboard />} />
        <Route path="/jamsJobDashboard" element={<JamsJobDashboard />} />
        <Route path="/securityUpdates" element={<SecurityUpdates />} />
        <Route
          path="/unspecifiedFieldDetails"
          element={<SecurityUnspecifiedFieldDetails />}
        />
        <Route path="settlementDashboard" element={<SettlementDashboard />} />
        <Route path="/suspenseAccount" element={<SuspenseAccount />} />
        <Route path="/tradePageMPID" element={<TradeBreakByMPID />} />
        <Route
          path="/intradayTradePageMPID"
          element={<IntraDayBreaksByMPID />}
        />
        <Route
          path="/intradayTradeBreaksByEC"
          element={<IntraDayBreaksByEC />}
        />
        <Route path="/tradeDetails" element={<TradeDetails />} />
        <Route
          path="/IntraDaytradeDetails"
          element={<IntraDayTradeDetails />}
        />

        <Route path="/tradeBreaksByEC" element={<TradeBreaksByEC />} />
        <Route path="/buyInReceive" element={<BuyInReceiveSettlements />} />
        <Route path="/buyInAdmin" element={<BuyInAdminWindow />} />
        <Route path="/buyInDeliver" element={<BuyInDeliverSettlements />} />
        <Route path="/204-FailReport" element={<FailReport204 />} />
        <Route path="/tradeFix" element={<TradeFix />} />
        <Route path="/Beta-FailTracking" element={<BetaFailDaily />} />
        <Route
          path="/Beta-FailTracking/history"
          element={<BetaFailHistory />}
        />
        <Route path="/tradeRemediation" element={<TradeRemediation />} />
        <Route
          path="/Securities-to-P3Translator"
          element={<SecuritiesToP3Translator />}
        />
        <Route path="/P3Reconciliation" element={<P3ReconciliationPage />} />
        <Route
          path="/FIS-IRS-ISO-Country-Codes"
          element={<CountryCodePage />}
        />
        <Route
          path="/FIS-IRS-ISO-Country-Reconciliation"
          element={<FISIRSISOCountryReconciliationPage />}
        />
        <Route
          path="/P3-Market-Maker-Securities"
          element={<P3MarketMakerSecuritiesPage />}
        />
        <Route
          path="/Beta-Stale-Pricing-Report"
          element={<BetaStalePricingPage />}
        />
        <Route
          path="/Beta-Stale-Pricing-History"
          element={<BetaStalePricingPageHistorical />}
        />
        <Route
          path="/P3-Stale-Pricing-Report"
          element={<P3StalePricingPage />}
        />
        <Route
          path="/p3-Stale-Pricing-History"
          element={<P3StalePricingPageHistorical />}
        />
        <Route path="/recon-history" element={<ReconHistoryPage />} />
        <Route path="/P3-FailTracking" element={<P3FailAnalysisDaily />} />
        <Route
          path="/P3-FailTracking/history"
          element={<P3FailAnalysisHistory />}
        />
        <Route path="/JAMSJobReport" element={<JAMSJobReport />} />
        <Route path="/ADR-Fees-Upload" element={<ADRFeesUploadPage />} />
        <Route path="/ADRPastFileUpload" element={<ADRPastFileUploadPage />} />
        <Route
          path="/Dividend-Open-Items-Reconciliation-and-Tracking-Tool"
          element={<DividendReconReport />}
        />
        <Route path="/ReOrgAdminReport" element={<ReOrgAdminPage />} />
        <Route
          path="/voluntaryEventsTrackingTool"
          element={<VoluntaryEventsTrackingPage />}
        />
        <Route path="/ReOrgFeeReport" element={<ReeOrgFeeForACAPSPage />} />

        <Route path="/REG-SHO-204" element={<RegSHOPage />} />
        <Route
          path="/settlementConfiguration"
          element={<SettlementConfiguration />}
        />
        <Route path="/TradeConfiguration" element={<TradeConfiguration />} />
        <Route
          path="/settlementContraCorres"
          element={<ContraCorrespondent />}
        />
        <Route
          path="/betaShortSalesTradeReport"
          element={<BetaTradeReportPage />}
        />
        <Route path="/BuyinExecutionReport" element={<OutStandingPage />} />
        <Route
          path="/BetaSecurityLendingReport"
          element={<BetaSecLendingPage />}
        />
        <Route path="/BETALoanetReconciliation" element={<BETALoanetRecon />} />
        <Route
          path="/SuspenseAccountCrossreferenceTable"
          element={<StreetSettlementsSuspenseAccount />}
        />
        <Route
          path="/HistoricalAuditTrailReport"
          element={<RegShoHistoryAudit />}
        />
        <Route path="/RegShoHistoryReport" element={<RegShoHistoryPage />} />
        <Route path="/" element={<AboutPage />} />
        <Route path="*" element={<Navigate to="/error-page" />} />
        <Route path="/error-page" element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}

function App({ msalInstance }: AppProps) {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <Suspense>
          <AllRoutes />
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default App
