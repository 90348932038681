import { combineReducers } from '@reduxjs/toolkit'
import ADRFeeUploadReport from './ADR-Fee-Upload-Report'
import ReeOrgFeeForACAPS from './reeorg-Fee-Report-for-acaps'
import reOrgFeeAdmin from './reorg-fee-admin'
import DividendReconReport from './DividendReconReport'
import VoluntaryEventsTrackingReport from './Voluntary-Events-Tracking-Report'
export const assetService = combineReducers({
  ADRFeeUploadReport,
  ReeOrgFeeForACAPS,
  reOrgFeeAdmin,
  DividendReconReport,
  VoluntaryEventsTrackingReport,
})
