import { useState } from 'react'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import {
  LottieWrapper,
  MarginTopSpace,
  PopUpStyleLoader,
  RootContainer,
  StyledButtonWrapper,
  StyledDateWrapper,
  StyledDeadLineContainer,
  StyledFilterPanel,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  ToastWrapper,
} from './styles'
import { Textbox } from '../../../components/textbox/Textbox'
import DatePicker from '../../../components/datePicker/DatePicker'
import Button from '../../../components/button/Button'
import SummaryColumns from './table/SummaryColumns'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { useDispatch, useSelector } from 'react-redux'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  TotalCountSelector,
  getVoluntaryEventsTrackingReportData,
  isLoadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
  sortDirectionSelector,
  sortingSelector,
  voluntaryEventsReportDataSelector,
} from '../../../store/asset-Servicing/Voluntary-Events-Tracking-Report'
import { Toast } from '../../../components/toast/Toast'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'

const VoluntaryEventsTracking = () => {
  const dispatch = useDispatch()
  const data = useSelector(voluntaryEventsReportDataSelector)
  const totalRecords = useSelector(TotalCountSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const loading = useSelector(isLoadingSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [gotoFirstPage, setGotoFirstPage] = useState<boolean>(true)
  const [input, setInput] = useState({
    caidOptionList: '',
    eventTypeOptionsList: '',
    cusip: '',
    regularDeadlineFrom: new Date().toISOString().split('T')[0],
    regularDeadlineTo: new Date().toISOString().split('T')[0],
    earlyDeadlineFrom: '',
    earlyDeadlineTo: '',
  })

  const CAIDOptions = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'option1',
      label: 'option1',
    },
    {
      value: 'option2',
      label: 'option2',
    },
  ]
  const EventTypeOptions = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'option1',
      label: 'option1',
    },
    {
      value: 'option2',
      label: 'option2',
    },
  ]

  const handleSearch = async () => {
    setGotoFirstPage(!gotoFirstPage)
    const response = await dispatch(
      getVoluntaryEventsTrackingReportData(
        'search',
        input.regularDeadlineFrom,
        input.regularDeadlineTo,
        input.caidOptionList,
        input.cusip,
        input.eventTypeOptionsList,
        input.earlyDeadlineFrom,
        input.earlyDeadlineTo,
        0,
        20,
        sorting,
        sortDirection
      )
    )
  }

  return (
    <RootContainer>
      <StyledPanelHead>
        <StyledPanelHeadTitle>
          Voluntary Events Tracking Tool
        </StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                CA ID
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={CAIDOptions}
                onChange={(options) => {
                  setInput({ ...input, caidOptionList: options })
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                CUSIP
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: string) => {
                  setInput({ ...input, cusip: e })
                }}
                placeholder={'CUSIP'}
                value={input.cusip}
              />
            </div>
            <StyledDeadLineContainer>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Regular Deadline
              </Label>
              <StyledDateWrapper>
                <DatePicker
                  padding={'4px 8px'}
                  onChange={(e: string) => {
                    setInput({ ...input, regularDeadlineFrom: e })
                  }}
                  value={input.regularDeadlineFrom}
                  max={new Date().toISOString().split('T')[0]}
                  bgColor=""
                  borderColor=""
                  placeHolder="From"
                  maxWidth="295px"
                  // defaultValue={new Date().toISOString().split('T')[0]}
                />
                <DatePicker
                  padding={'4px 8px'}
                  onChange={(e: string) => {
                    setInput({ ...input, regularDeadlineTo: e })
                  }}
                  value={input.regularDeadlineTo}
                  max={new Date().toISOString().split('T')[0]}
                  bgColor=""
                  borderColor=""
                  placeHolder="From"
                  maxWidth="295px"
                  // defaultValue={new Date().toISOString().split('T')[0]}
                />
              </StyledDateWrapper>
            </StyledDeadLineContainer>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Event Type
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={EventTypeOptions}
                onChange={(options) => {
                  setInput({ ...input, eventTypeOptionsList: options })
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <StyledDeadLineContainer>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Early Deadline
              </Label>
              <StyledDateWrapper>
                <DatePicker
                  padding={'4px 8px'}
                  onChange={(e: string) => {
                    setInput({ ...input, earlyDeadlineFrom: e })
                  }}
                  value={input.earlyDeadlineFrom}
                  max={new Date().toISOString().split('T')[0]}
                  bgColor=""
                  borderColor=""
                  placeHolder="From"
                  maxWidth="295px"
                  // defaultValue={new Date().toISOString().split('T')[0]}
                />
                <DatePicker
                  padding={'4px 8px'}
                  onChange={(e: string) => {
                    setInput({ ...input, earlyDeadlineTo: e })
                  }}
                  value={input.earlyDeadlineTo}
                  max={new Date().toISOString().split('T')[0]}
                  bgColor=""
                  borderColor=""
                  placeHolder="From"
                  maxWidth="295px"
                  // defaultValue={new Date().toISOString().split('T')[0]}
                />
              </StyledDateWrapper>
            </StyledDeadLineContainer>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
      </StyledPanelSelect>

      <>
        <MarginTopSpace>
          <WDCard>
            <CardHeader
              label={'Voluntary Events Tracking Tool'}
              icon={false}
              amountValue={`Total: ${totalRecords}`}
            />
          </WDCard>
        </MarginTopSpace>
        <SummaryColumns
          data={data ? data : []}
          regularDeadlineFrom={input.regularDeadlineFrom}
          regularDeadlineTo={input.regularDeadlineTo}
          caidOptionList={input.caidOptionList}
          cusip={input.cusip}
          eventTypeOptionsList={input.eventTypeOptionsList}
          earlyDeadlineFrom={input.earlyDeadlineFrom}
          earlyDeadlineTo={input.earlyDeadlineTo}
          totalRecords={totalRecords}
          handleSearch={handleSearch}
          gotoFirstPage={gotoFirstPage}
        ></SummaryColumns>
      </>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default VoluntaryEventsTracking
