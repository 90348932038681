/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  data: any
  isLoading: boolean
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  data: [],
  isLoading: false,
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'suspense-account-report',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setCurrentData,
  setIsLoading,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getSuspenseAccountReportData: any =
  () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        'opsfailprocessapi/FailAnalysisReport/GetStreetSettlementSuspenseAccount'
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setCurrentData(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
    dispatch(setIsLoading(false))
  }

export const addSuspenseAccount: any =
  (accountNumber: string, active: string, department: string, source: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddStreetSettlementSuspenseAccount',
        {
          accountNumber,
          active,
          department,
          source,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const updateAccountList: any =
  (id: number, active: string, department: string, source: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.put(
        `opsfailprocessapi/FailAnalysisReport/UpdateStreetSettlementSuspenseAccount?id=${id}&active=${active}&function=${department}&source=${source}`
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const getSuspenseAccountExcel: any =
  (fileName: string, type: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetStreetSettlementSuspenseAccountExcel',
        {},
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const deleteAccountRecordDetails: any =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.delete(
        `opsfailprocessapi/FailAnalysisReport/DeleteStreetSettlementSuspenseAccount?Id=${id}`
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Records Deleted Successfully'))
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const currentDataSelector = (store: StoreState) =>
  store.operationsControl.suspenseAccountReport.data
export const LoadingSelector = (store: StoreState) =>
  store.operationsControl.suspenseAccountReport.isLoading
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.operationsControl.suspenseAccountReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.operationsControl.suspenseAccountReport.successMessage

export default reducer
